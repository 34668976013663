.skeleton-loader {
  height: 350px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media (max-width: 768px) {
  .skeleton-loader {
    height: 130px;
    border-radius: 0;
  }
}
