.hp-navigation {
  margin-bottom: 20px;
}
.hp-navigation__box {
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 235px;
  position: relative;
  border-radius: 5px;
  border: 1px solid transparent;
}
.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  line-height: 32px;
}

.skin_449
  .hp-navigation__box.box-total
  .hp-navigation__box--content.full-width {
  width: 100%;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content.first-row {
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content.item2 {
  -webkit-box-ordinal-group: 3;
  order: 2;
}
.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h2 {
  text-transform: uppercase;
  line-height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-size: 20px;
  color: var(--iq-white);
  margin-bottom: 0;
  font-weight: 300;
}
.page-rtl .hp-navigation__box--content {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  text-align: right;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: end;
  align-items: flex-end;
}
.page-rtl .hp-navigation__inner--top .hp-navigation__box--content {
  top: 22px;
  right: 25px;
}
.hp-navigation__box.box-results .hp-navigation__box--content {
  position: relative;
  top: unset;
  left: unset;
}
.page-rtl .hp-navigation__box.box-results .hp-navigation__box--content h2 {
  margin-bottom: 16px;
}
.hp-navigation__box--content a {
  width: 137px;
  height: 40px;
}

.hp-navigation__box.box-results .hp-navigation__box--content h2 {
  font-size: 38px;
  margin-bottom: 5px;
  color: var(--iq-white);
}

.hp-navigation__box.box-results .hp-navigation__box--btn {
  display: -webkit-box;
  display: flex;
}

.hp-navigation__box.box-results .hp-navigation__box--btn a {
  font-size: 14px;
  max-width: 100%;
  height: 40px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 15px 20px 12px;
  width: auto;
}
.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content span {
  text-transform: uppercase;
  font-size: 35px;
  color: var(--iq-white);
}
.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h4 {
  color: var(--iq-white);
  text-transform: uppercase;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
  color: #0078f5;
  font-size: 60px;
  margin-bottom: 0;
}
.skin_449 .hp-navigation__box.box-total .hp-navigation__box--price {
  display: -webkit-box;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--price.item3 {
  -webkit-box-ordinal-group: 4;
  order: 3;
}

.skin_449 .hp-navigation__box.box-total .hp-navigation__box--price h3 {
  font-size: 40px;
  position: relative;
  color: var(--iq-yellow);
}
.hp-navigation__box.box-total.desktop > div.hp-nav_box-wrap {
  padding-right: 50px;
  border-right: 2px solid white;
}

.hp-navigation__box.box-total.desktop > div.hp-nav_box-wrapar {
  padding-left: 50px;
  border-left: 2px solid white;
}
.skin_449 .hp-navigation__box.box-total {
  background: var(--iq-pink);
  padding: 14px 20px;
  border-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border: 1px solid var(--iq-orange);
}
@media (min-width: 992px) {
  .skin_449 .hp-navigation__box.box-total.mobile {
    display: none;
  }
}
@media (max-width: 1199px) {
  .hp-navigation__box {
    background-position: 50%;
  }
  .hp-navigation__box--content {
    top: 30px;
  }
  .hp-navigation__box.box-results .hp-navigation__box--content h2 {
    font-size: 28px;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content span {
    font-size: 28px;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
    font-size: 60px;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--price h3 {
    font-size: 26px;
  }
  .hp-navigation__box.box-total.desktop > div.hp-nav_box-wrap {
    padding-right: 20px;
  }

  .hp-navigation__box.box-total.desktop > div.hp-nav_box-wrapar {
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .hp-navigation {
    margin-bottom: 0px;
  }
  .hp-navigation__box.box-results .hp-navigation__box--content {
    padding: 0;
    left: 0;
  }
  .skin_449 .hp-navigation__box.box-total.desktop {
    display: none;
  }
  .skin_449 .hp-navigation__inner > .row > [class*="col-"]:first-child {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
}
@media (max-width: 575px) {
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .skin_449 .hp-navigation__box.box-total {
    padding: 10px 20px 5px;
    border-radius: 10px;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h2 {
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
    font-size: 50px;
    color: #0078f5;
    line-height: 80px;
    width: 100%;
    max-width: 100%;
    text-align: right;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--price h3 {
    font-size: 22px;
  }
}

@media (max-width: 479px) {
  .hp-navigation__box {
    padding-bottom: 190px;
  }
  .hp-navigation__box--content {
    left: 20px;
  }
  .page-rtl .hp-navigation__inner--top .hp-navigation__box--content {
    top: 16px;
  }
  .hp-navigation__box.box-results .hp-navigation__box--content h2 {
    font-size: 50px;
    margin-bottom: 0;
  }
  .page-rtl .hp-navigation__box.box-results .hp-navigation__box--content h2 {
    font-size: 38px !important;
  }

  .hp-navigation__box.box-results .hp-navigation__box--btn a {
    font-size: 20px;
    width: 220px;
    height: 45px;
  }
}
@media (max-width: 412px) {
  .hp-navigation__box.box-results .hp-navigation__box--btn a {
    font-size: 17px;
    width: 200px;
    height: 35px;
  }
  .page-rtl .hp-navigation__box.box-results .hp-navigation__box--content h2 {
    font-size: 32px !important;
  }
}

@media (max-width: 375px) {
  .page-rtl .hp-navigation__inner--top .hp-navigation__box--content {
    right: 20px;
  }
  .skin_449 .hp-navigation__box.box-total .hp-navigation__box--content h3 {
    font-size: 55px;
    line-height: 55px;
  }
}
@media (max-width: 350px) {
  .hp-navigation__box--content h2 {
    margin-bottom: 10px;
  }
  .hp-navigation__box.box-results .hp-navigation__box--content {
    max-width: 290px !important;
  }
}
