.skeleton {
  border: 1px solid #eee !important;
}
.skeleton-one {
  margin-right: 10px;
}
.page-ar .skeleton-one {
  margin-left: 10px;
}
.skeleton-stories {
  display: inline-block;
  opacity: 0.7;
  background-color: #e0e0e0;
  width: 100%;
  height: calc(100% - 15px);
}
@media (max-width: 576px) {
  .circle-wrapper .skeleton {
    flex: 0 0 281px;
  }
  .circle-wrapper {
    overflow: auto;
  }
  .circle-wrapper .w-10 {
    width: 1.7rem;
  }
  .circle-wrapper .h-10 {
    height: 1.7rem;
  }
  .circle-wrapper .h-14 {
    height: 2.5rem;
  }
  .skeleton-wrapper .h-10 {
    height: 2rem;
  }
  .skeleton-wrapper .w-1\/4 {
    width: 35%;
  }
}
